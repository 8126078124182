import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { AboutUsImagesQuery } from './AboutUs.interfaces';
import * as styles from './AboutUs.module.scss';

export const AboutUs: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<AboutUsImagesQuery>(graphql`
        query {
            doctorsImage: file(name: {eq: "doctors3"}, extension: {eq: "png"}) {
                publicURL
            }
        }
    `);

    return (
        <section className={styles.aboutUs}>
            <img
                src={queryResult?.doctorsImage.publicURL}
                alt="Image of doctors"
                data-aos="fade-up"
                data-aos-duration="900"
                loading="lazy"
            />
            <p data-aos="fade-up" data-aos-duration="900">
                {`
                    Pacific Crest Healthcare Independent Provider Association (PCH IPA) was founded by a trendsetting visionary with over 20 years of med-legal experience in personal injury. We created PCH IPA because of our unwavering commitment to delivering exceptional service and our unparalleled clinical experience in driving outcomes. Our unique approach to the world of independent provider associations has classified us as a truly one-of-a-kind IPA. We are extremely proud to be a pioneer as we work to bridge the gap between the medical and legal fields. 
                `}
            </p>
            <p data-aos="fade-up" data-aos-duration="900">
                {`
                    PCH IPA offers comprehensive solutions, for attorneys working up their cases to providers treating their patients. We have taken the concept of a provider network and transformed it into a technology company while preserving the art of medicine. Our state-of-the-art, patent-pending software combines case management and an extensive, statewide medical network, all at the touch of a button.
                `}
            </p>
            <p data-aos="fade-up" data-aos-duration="900">
                {`
                    With PCH IPA, we have created a one-stop shop for attorneys. Imagine a single-entity bill for all of your clients’ treatment… then stop imagining, and start experiencing.
                `}
            </p>
            <p data-aos="fade-up" data-aos-duration="900">{`Welcome to PCH!`}</p>
        </section>
    );
}
