import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FetchSimpleHomeJumbotronImagesQuery } from './SimpleHomeJumbotron.interfaces';
import * as styles from './SimpleHomeJumbotron.module.scss';

export const SimpleHomeJumbotron: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<FetchSimpleHomeJumbotronImagesQuery>(graphql`
        query {
            backgroundImage1: file(name: {eq: "homePageBackgroundImage"}, extension: {eq: "jpg"}) {
                publicURL
            }
        }
    `);

    return (
        <section id={styles.simpleHomeJumbotronSection}>
            <img
                className={styles.backgroundImage}
                src={queryResult?.backgroundImage1.publicURL}
                alt="Background image"
                loading="lazy"
            />
            <button
                className={styles.loginButton}
                type="button"
            >{`Login`}</button>
        </section>
    );
}
