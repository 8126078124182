import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { FetchExperienceCardsImagesQuery, Card } from './ExperienceCards.interfaces';
import * as styles from './ExperienceCards.module.scss';

export const ExperienceCards: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<FetchExperienceCardsImagesQuery>(graphql`
        query {
            attorneyCard: file(name: {eq: "attorneyCard"}, extension: {eq: "png"}) {
                publicURL
            }
            dmeCard: file(name: {eq: "dmeCard"}, extension: {eq: "png"}) {
                publicURL
            }
            imagingCard: file(name: {eq: "imagingCard"}, extension: {eq: "png"}) {
                publicURL
            }
            laboratoryCard: file(name: {eq: "laboratoryCard"}, extension: {eq: "png"}) {
                publicURL
            }
            logoCard: file(name: {eq: "logoCard"}, extension: {eq: "png"}) {
                publicURL
            }
            pharmacyCard: file(name: {eq: "pharmacyCard"}, extension: {eq: "png"}) {
                publicURL
            }
            providerCard: file(name: {eq: "editedDoctor"}, extension: {eq: "png"}) {
                publicURL
            }
            marketingCard: file(name: {eq: "marketingBackground"}, extension: {eq: "jpg"}) {
                publicURL
            }
            telemedicineCard: file(name: {eq: "telemedicineCard"}, extension: {eq: "png"}) {
                publicURL
            }
            translationCard: file(name: {eq: "translationCard"}, extension: {eq: "png"}) {
                publicURL
            }
            transportationCard: file(name: {eq: "transportationCard"}, extension: {eq: "png"}) {
                publicURL
            }
            urgentCareCard: file(name: {eq: "urgentCareCard"}, extension: {eq: "png"}) {
                publicURL
            }
            whiteLogo: file(name: {eq: "whiteLogo"}, extension: {eq: "png"}) {
                publicURL
            }
            ascCard: file(name: {eq: "ascCard"}, extension: {eq: "jpg"}) {
                publicURL
            }
            chiropractorCard: file(name: {eq: "chiropractorCard"}, extension: {eq: "jpg"}) {
                publicURL
            }
            financeCard: file(name: {eq: "financeCard"}, extension: {eq: "jpg"}) {
                publicURL
            }
            hospitalsCard: file(name: {eq: "hospitalsCard"}, extension: {eq: "jpg"}) {
                publicURL
            }
            membershipCard: file(name: {eq: "membershipCard"}, extension: {eq: "jpg"}) {
                publicURL
            }
            outOfNetworkCard: file(name: {eq: "outOfNetworkCard"}, extension: {eq: "jpg"}) {
                publicURL
            }
            marketingLogo: file(name: {eq: "marketingLogo"}, extension: {eq: "png"}) {
                publicURL
            }
            dentalCard: file(name: {eq: "dentalBackground"}, extension: {eq: "jpg"}) {
                publicURL
            }
        }
    `);

    const cards: Card[] = [
        {
            text: '',
            imageURL: queryResult?.logoCard.publicURL,
            abnormalImage: false,
            id: 'logo',
            link: '/pch',
        },
        {
            text: 'embership',
            imageURL: queryResult?.membershipCard.publicURL,
            abnormalImage: false,
            id: 'membership',
            link: '/membership',
            sectionText: 'M',
        },
        {
            text: 'ttorney',
            imageURL: queryResult?.attorneyCard.publicURL,
            abnormalImage: true,
            id: 'attorney',
            link: '/attorney',
            sectionText: 'A'
        },
        {
            text: 'rovider',
            imageURL: queryResult?.providerCard.publicURL,
            abnormalImage: true,
            id: 'provider',
            link: '/provider',
            sectionText: 'P'
        },
        {
            text: 'eleMed',
            imageURL: queryResult?.telemedicineCard.publicURL,
            abnormalImage: false,
            id: 'telemedicine',
            link: '/telemedicine',
            sectionText: 'T',
        },
        {
            text: 'maging',
            imageURL: queryResult?.imagingCard.publicURL,
            abnormalImage: false,
            id: 'imaging',
            link: '/imaging',
            sectionText: 'I',
        },
        {
            text: 'harmacy',
            imageURL: queryResult?.pharmacyCard.publicURL,
            abnormalImage: false,
            id: 'pharmacy',
            link: '/pharmacy',
            sectionText: 'P',
        },
        {
            text: 'hiropractor',
            imageURL: queryResult?.chiropractorCard.publicURL,
            abnormalImage: false,
            id: 'chiropractors',
            link: '/chiropractors',
            sectionText: 'C',
        },
        {
            text: 'SC',
            imageURL: queryResult?.ascCard.publicURL,
            abnormalImage: false,
            id: 'asc',
            link: '/asc',
            sectionText: 'A',
        },
        {
            text: 'ospital',
            imageURL: queryResult?.hospitalsCard.publicURL,
            abnormalImage: false,
            id: 'hospitals',
            link: '/hospitals',
            sectionText: 'H',
        },
        {
            text: 'rgent Care',
            imageURL: queryResult?.urgentCareCard.publicURL,
            abnormalImage: false,
            id: 'urgent-care',
            link: '/urgent-care',
            sectionText: 'U',
        },
        {
            text: 'ME',
            imageURL: queryResult?.dmeCard.publicURL,
            abnormalImage: false,
            id: 'dme',
            link: '/dme',
            sectionText: 'D',
        },
        {
            text: 'ental',
            imageURL: queryResult?.dentalCard.publicURL,
            abnormalImage: false,
            id: 'dental',
            link: '/dental',
            sectionText: 'D',
        },
        {
            text: 'abs',
            imageURL: queryResult?.laboratoryCard.publicURL,
            abnormalImage: false,
            id: 'laboratory',
            link: '/laboratory',
            sectionText: 'L',
        },
        {
            text: 'ut of Network',
            imageURL: queryResult?.outOfNetworkCard.publicURL,
            abnormalImage: false,
            id: 'out-of-network',
            link: '/out-of-network',
            sectionText: 'O',
        },
        {
            text: 'ransportation',
            imageURL: queryResult?.transportationCard.publicURL,
            abnormalImage: false,
            id: 'transportation',
            link: '/transportation',
            sectionText: 'T',
        },
        {
            text: 'ranslation',
            imageURL: queryResult?.translationCard.publicURL,
            abnormalImage: false,
            id: 'translation',
            link: '/translation',
            sectionText: 'T',
        },
        // {
        //     text: 'inancial',
        //     imageURL: queryResult?.financeCard.publicURL,
        //     abnormalImage: false,
        //     id: 'financial',
        //     link: '/financial',
        //     sectionText: 'F',
        // },
    ];

    return (
        <section className={styles.experienceCards}>
            {cards.map((card: Card) => (
                <Link
                    key={card.id}
                    to={card.link}
                    className={styles.card}
                    id={card.id}
                >
                    <div className={styles.overlay}></div>
                    <img
                        className={styles.backgroundImage}
                        src={card.imageURL}
                        alt={`${card.text} card background image`}
                        loading="lazy"
                    />
                    {card.id === 'logo' &&
                        <img
                            className={styles.logo}
                            src={queryResult?.whiteLogo.publicURL}
                            alt="Light version of logo"
                            loading="lazy"
                        />
                    }
                    <strong>
                        <span>{card.sectionText}</span>
                        {card.text}
                    </strong>
                </Link>
            ))}
        </section>
    );
}
