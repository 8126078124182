import React, {
    FunctionComponent,
    ReactElement,
} from 'react';
import {
    useStaticQuery,
    graphql,
} from 'gatsby';
import { ProviderSummary1Query } from './ProviderSummary1.interfaces';
import * as styles from './ProviderSummary1.module.scss';

export const ProviderSummary1: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<ProviderSummary1Query>(graphql`
        query {
            providerCare: file(name: {eq: "providerCare"}, extension: {eq: "jpg"}) {
                publicURL
            }
        }
    `);

    return (
        <section className={styles.providerSummary1}>
            <div
                className={styles.contentWrapper}
                data-aos="fade-right"
                data-aos-duration="900"
            >
                <h2>{`Hand Selected Network`}</h2>
                <p>
                    {`
                        At PCH IPA, we value our providers and view them as partners
                        when it comes to patient care. We are proud to say we
                        hand-select our network as our attorneys trust us for quality
                        care. All providers go through an extensive screening and
                        credentialing process prior to joining the network. We want
                        to ensure our providers are of outstanding character, provide
                        first-class care, and have a track record of positive patient
                        outcomes.
                    `}
                </p>
            </div>
            <div
                className={styles.imageWrapper}
                data-aos="fade-left"
                data-aos-duration="900"
            >
                <img
                    alt="Provider working"
                    src={queryResult?.providerCare.publicURL}
                    loading="lazy"
                />
            </div>
        </section>
    );
}
