import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import * as styles from './ProviderSummary3.module.scss';
import { ProviderSummary3Query } from './ProviderSummary3.interfaces';

export const ProviderSummary3: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<ProviderSummary3Query>(graphql`
        query {
            doctors: file(name: {eq: "providerDoctors"}, extension: {eq: "jpg"}) {
                publicURL
            }
        }
    `);

    return (
        <section className={styles.providerSummary3}>
            <div className={styles.contentWrapper}>
                <img
                    src={queryResult?.doctors.publicURL}
                    alt="Doctors image"
                    data-aos="fade-up"
                    data-aos-duration="900"
                    loading="lazy"
                />
                <p
                    data-aos="fade-up"
                    data-aos-duration="900"
                >
                    {`If you are not a current provider with PCH IPA, we invite you to join! We have the best reimbursing contracts! You won't be disappointed! Call us today at `}
                    <a href="tel:+18337244111">
                        <FontAwesomeIcon icon={faPhoneAlt}/>
                        {`1-833-PCH-4111`}
                    </a>
                    {`, and we will send you a provider application and agreement right away!`}
                </p>
            </div>
        </section>
    );
}
