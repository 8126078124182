import React, {
    FunctionComponent,
    ReactElement,
} from 'react';
import {
    useStaticQuery,
    graphql,
} from 'gatsby';
import { PharmacySummary1Query } from './PharmacySummary1.interfaces';
import * as styles from './PharmacySummary1.module.scss';

export const PharmacySummary1: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<PharmacySummary1Query>(graphql`
        query {
            pharmacist: file(name: {eq: "pharmacySummary"}, extension: {eq: "png"}) {
                publicURL
            }
            pharmacyMembershipCard: file(name: {eq: "pharmacy-member-card"}, extension: {eq: "jpg"}) {
                publicURL
            }
        }
    `);

    return (
        <section className={styles.pharmacySummary1}>
            <div
                className={styles.imageWrapper}
                data-aos="fade-up"
                data-aos-duration="900"
            >
                <img
                    className={styles.membershipCard}
                    alt="Pharmacy member card"
                    src={queryResult?.pharmacyMembershipCard.publicURL}
                    loading="lazy"
                />
                <img
                    alt="Pharmacist working"
                    src={queryResult?.pharmacist.publicURL}
                    loading="lazy"
                />
            </div>
            <div
                className={styles.contentWrapper}
                data-aos="fade-up"
                data-aos-duration="900"
            >
                <p>
                    {`
                        At PCH IPA, we have an extensive network of pharmacies statewide.
                        At every location, an onsite pharmacist is there to respond to
                        questions your clients may have regarding their meds. We take pride
                        in knowing your clients will be well-informed about the medications
                        they are taking. Members of PCH can search easily for nearby
                        pharmacies directly on our website. To fill a prescription, simply
                        have your client present their PCH enrollment card, and we will
                        handle the rest.
                    `}
                </p>
            </div>
        </section>
    );
}
