import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { AttorneySummary1Query } from './AttorneySummary1.interfaces';
import * as styles from './AttorneySummary1.module.scss';

export const AttorneySummary1: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<AttorneySummary1Query>(graphql`
        query {
            attorneyWorking: file(name: {eq: "attorneyWorking"}, extension: {eq: "png"}) {
                publicURL
            }
        }
    `);

    return (
        <section className={styles.attorneySummary1}>
            <div
                className={styles.contentWrapper}
                data-aos="fade-up"
                data-aos-duration="900"
            >
                <h2>{`Med-Legal Platform`}</h2>
                <p>
                    {`
                        What is the biggest issue for your company? If you said “connectivity,” then Pacific Crest Healthcare (PCH) IPA is perfect for you.
                    `}
                </p>
                <p>
                    {`
                        At PCH IPA, we offer cutting-edge software that will keep you connected with your clients and their medical treatments. As treatments gets scheduled, our easily accessible, 24/7 online medical portal is automatically updated.
                    `}
                </p>
                <p>
                    {`
                        The PCH case management software is a med-legal platform, which will keep your clients’ data current and secure on one system for easy file management. Our state-of-the-art software can also be integrated with other case management platforms, e.g., Filevine and Litify. Our system gives you the ability to lower your overhead, saving you time and money.
                    `}
                </p>
            </div>
            <div
                className={styles.imageWrapper}
                data-aos="fade-up"
                data-aos-duration="900"
            >
                <img
                    alt="Attorney working"
                    src={queryResult?.attorneyWorking.publicURL}
                    loading="lazy"
                />
            </div>
        </section>
    );
}
