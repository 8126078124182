import React, {
    FunctionComponent,
    ReactElement,
} from 'react';
import {
    useStaticQuery,
    graphql,
    Link,
} from 'gatsby';
import { FetchExperienceImagesQuery } from './Experience.interfaces';
import * as styles from './Experience.module.scss';

export const Experience: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<FetchExperienceImagesQuery>(graphql`
        query {
            attorneyImage: file(name: {eq: "attorneys-pchipa"}, extension: {eq: "png"}) {
                publicURL
            }
            providerImage: file(name: {eq: "provider"}, extension: {eq: "jpg"}) {
                publicURL
            }
            telemedicineImage: file(name: {eq: "telemedicine2"}, extension: {eq: "jpg"}) {
                publicURL
            }
        }
    `);

    return (
        <section id={styles.experienceSection}>
            <div className={styles.cardWrapper}>
                <Link to="/attorney">
                    <div className={styles.card}>
                        <img
                            src={queryResult?.attorneyImage.publicURL}
                            alt="Attorney image"
                            loading="lazy"
                        />
                        <strong>{`Attorney`}</strong>
                    </div>
                </Link>
                <Link to="/provider">
                    <div className={styles.card}>
                        <img
                            src={queryResult?.providerImage.publicURL}
                            alt="Provider image"
                            loading="lazy"
                        />
                        <strong>{`Provider`}</strong>
                    </div>
                </Link>
                <Link to="/telemedicine">
                    <div className={styles.card}>
                        <img
                            src={queryResult?.telemedicineImage.publicURL}
                            alt="Telemedicine image"
                            loading="lazy"
                        />
                        <strong>{`Telemedicine`}</strong>
                    </div>
                </Link>
            </div>
        </section>
    );
}
