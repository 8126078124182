import React, {
    FunctionComponent,
    ReactElement,
    useEffect,
} from 'react';
import {
    useStaticQuery,
    graphql,
} from 'gatsby';
import {
    ScheduleDemoQuery,
    FormField,
} from './ScheduleDemo.interfaces';
import * as styles from './ScheduleDemo.module.scss';

export const ScheduleDemo: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<ScheduleDemoQuery>(graphql`
        query {
            formBackgroundImage: file(name: {eq: "formBackgroundImage"}, extension: {eq: "png"}) {
                publicURL
            }
        }
    `);

    useEffect(() => {
        // Get section element
        const sectionEl: HTMLElement | null = document.querySelector(`.${styles.scheduleDemo}`);

        // Set background image
        sectionEl?.setAttribute('style', `
            background-image: url("${queryResult.formBackgroundImage.publicURL}");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        `);
    }, []);

    const formFields: FormField[] = [
        { key: 0, placeholder: 'First Name' },
        { key: 1, placeholder: 'Second Name' },
        { key: 2, placeholder: 'Company' },
        { key: 3, placeholder: 'Employees' },
        { key: 4, placeholder: 'Email' },
        { key: 5, placeholder: 'Phone' },
    ];

    return (
        <section className={styles.scheduleDemo}>
            <h2>{`Schedule Live Demo`}</h2>
            <form>
                <div className={styles.fieldsWrapper}>
                    {formFields.map(({ key, placeholder }: FormField) => (
                        <input
                            key={key}
                            type="text"
                            placeholder={placeholder}
                        />
                    ))}
                </div>
                <button
                    type="submit"
                    className=""
                >
                    {`Schedule Demo`}
                </button>
            </form>
        </section>
    );
}
