import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SponsorsImagesQuery, Sponsor } from './Sponsors.interfaces';
import * as styles from './Sponsors.module.scss';

export const Sponsors: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<SponsorsImagesQuery>(graphql`
        query {
            codefund: file(name: {eq: "codefund"}, extension: {eq: "svg"}) {
                publicURL
            }
            craftcms: file(name: {eq: "craftcms"}, extension: {eq: "svg"}) {
                publicURL
            }
            drupal: file(name: {eq: "drupal"}, extension: {eq: "svg"}) {
                publicURL
            }
            freedcamp: file(name: {eq: "freedcamp"}, extension: {eq: "svg"}) {
                publicURL
            }
            ad1: file(name: {eq: "guides-banner"}, extension: {eq: "jpg"}) {
                publicURL
            }
            sponsor1: file(name: {eq: "sponsor-1"}, extension: {eq: "png"}) {
                publicURL
            }
            sponsor2: file(name: {eq: "sponsor-2"}, extension: {eq: "png"}) {
                publicURL
            }
            sponsor3: file(name: {eq: "sponsor-3"}, extension: {eq: "jpg"}) {
                publicURL
            }
        }
    `);

    let sponsors: Sponsor[] = [
        { key: 0, imageURL: queryResult?.ad1.publicURL },
        { key: 1, imageURL: queryResult?.codefund.publicURL, link: 'https://www.codefund.io/' },
        { key: 2, imageURL: queryResult?.craftcms.publicURL, link: 'https://craftcms.com' },
        { key: 3, imageURL: queryResult?.drupal.publicURL, link: 'https://www.drupal.org/' },
        { key: 4, imageURL: queryResult?.freedcamp.publicURL, link: 'https://freedcamp.com/' },
        { key: 5, imageURL: queryResult?.sponsor1.publicURL, link: '' },
        { key: 6, imageURL: queryResult?.sponsor2.publicURL, link: '' },
        { key: 7, imageURL: queryResult?.sponsor3.publicURL, link: '' },
    ];

    return (
        <section className={styles.sponsors}>
            <div className={styles.sponsorsWrapper}>
                {sponsors.map((sponsor: Sponsor) => {
                    if (sponsor.link) {
                        return (
                            <a
                                key={sponsor.key}
                                href={sponsor.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                data-aos="fade-up"
                                data-aos-duration="800"
                            >
                                <img
                                    src={sponsor.imageURL}
                                    alt={`Sponsor ${sponsor.key} image`}
                                />
                            </a>
                        );
                    }
                    
                    return (
                        <img
                            key={sponsor.key}
                            src={sponsor.imageURL}
                            alt={`Sponsor ${sponsor.key} image`}
                            data-aos="fade-up"
                            data-aos-duration="800"
                            loading="lazy"
                        />
                    );
                })}
            </div>
        </section>
    );
}
