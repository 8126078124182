import {
    createContext,
    Context,
} from  'react';
import { InterfaceType } from './Interface.interfaces';

export const defaultInterfaceContext: InterfaceType = {
    isNavOpen: false,
    toggleNav: () => { /** do nothing */ },
};

export const Interface: Context<InterfaceType> = createContext(defaultInterfaceContext);
