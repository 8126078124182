import React, { FunctionComponent, ReactElement, useContext } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Location, LocationContext } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FetchNavbarImagesQuery, LinkObject } from './Navbar.interfaces';
import * as styles from './Navbar.module.scss';
import { Interface, InterfaceType } from '../../contexts';

export const Navbar: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<FetchNavbarImagesQuery>(graphql`
        query {
            logo: file(name: {eq: "logo-white"}, extension: {eq: "png"}) {
                publicURL
            }
            mobileLogo: file(name: {eq: "whiteLogo"}, extension: {eq: "png"}) {
                publicURL
            }
        }
    `);
    const { toggleNav } = useContext<InterfaceType>(Interface);

    const links: LinkObject[] = [
        { text: 'The Experience', link: '/experience', key: 'experience' },
        // { text: 'Attorney', link: '/attorney', key: 'attorney' },
        // { text: 'Provider', link: '/provider', key: 'provider' },
        // { text: 'Telemedicine', link: '/telemedicine', key: 'telemedicine' },
        // { text: 'About Us', link: '/about-us', key: 'about-us' },
    ];

    return (
        <Location>
            {(locationProps: LocationContext) => {
                return (
                    <header
                        id={styles.pchHeader}
                        className={locationProps.location.pathname === '/experience' || locationProps.location.pathname === '/experience/' ? styles.experienceOverride : ''}
                    >
                        <div className={styles.logoWrapper}>
                            <Link to="/">
                                <img
                                    src={queryResult?.logo.publicURL}
                                    alt="PCH logo"
                                    loading="lazy"
                                />
                            </Link>
                        </div>
                        <div className={styles.mobileLogoWrapper}>
                            <Link to="/">
                                <img
                                    src={queryResult?.mobileLogo.publicURL}
                                    alt="Mobile PCH logo"
                                    loading="lazy"
                                />
                            </Link>
                        </div>
                        <button
                            type="button"
                            className={styles.hamburgerWrapper}
                        >
                            <FontAwesomeIcon
                                icon={faBars}
                                onClick={() => toggleNav()}
                            />
                        </button>
                        <nav className={styles.pchNavigation}>
                            <a
                                className={styles.phoneLink}
                                href="tel:+18337244111"
                            >
                                <FontAwesomeIcon
                                    className={styles.iconCorrection}
                                    icon={faPhoneAlt}
                                />
                                <p>{`1-833-PCH-4111`}</p>
                            </a>
                            <ul className={styles.navList}>
                                {links.map((link: LinkObject) => {
                                    if (link.key === 'experience') {
                                        return (
                                            <li
                                                key={link.key}
                                                className={`${styles.listItem} ${styles.pulseCorrection} animate__animated animate__pulse animate__infinite`}
                                            >
                                                <Link
                                                    className={styles.experienceButton}
                                                    to={link.link}
                                                >{link.text}</Link>
                                            </li>
                                        );
                                    }

                                    return (
                                        <li
                                            key={link.key}
                                            className={styles.listItem}
                                        >
                                            <Link to={link.link}>{link.text}</Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </nav>
                    </header>
                );
            }}
        </Location>
    );
}
