import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Props, SummaryTemplateImagesQuery } from './SummaryTemplate.interfaces';
import * as styles from './SummaryTemplate.module.scss';
import * as sharedStyles from '../../styles/shared.module.scss';

export const SummaryTemplate: FunctionComponent<Props> = (props: Props): ReactElement => {
    const queryResult = useStaticQuery<SummaryTemplateImagesQuery>(graphql`
        query {
            pharmacist: file(name: {eq: "pharmacist"}, extension: {eq: "png"}) {
                publicURL
            }
        }
    `);

    return (
        <section className={sharedStyles.sharedStyling}>
            <img
                src={props.image ? props.image : queryResult?.pharmacist.publicURL}
                alt="Summary image"
                data-aos="fade-right"
                data-aos-duration="800"
                loading="lazy"
            />
            <div
                className={sharedStyles.sharedContentWrapper}
                data-aos="fade-left"
                data-aos-duration="800"
            >
                {props.children &&
                    props.children
                }
            </div>
        </section>
    );
}
