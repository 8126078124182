import React, { FunctionComponent, ReactElement } from 'react';
import * as styles from './CallToAction.module.scss';

export const CallToAction: FunctionComponent = (): ReactElement => {
    return (
        <section className={styles.callToAction}>
            <strong
                data-aos="fade-up"
                data-aos-duration="900"
            >
                {`For a full list of products and services`}
            </strong>
            <a
                href="tel:+18337244111"
                data-aos="fade-up"
                data-aos-duration="900"
            >{`1-833-PCH-4111`}</a>
        </section>
    );
}
