// extracted by mini-css-extract-plugin
export var pchHeader = "Navbar-module--pchHeader--WxyDs";
export var experienceOverride = "Navbar-module--experienceOverride--1j_V4";
export var logoWrapper = "Navbar-module--logoWrapper--TAG92";
export var mobileLogoWrapper = "Navbar-module--mobileLogoWrapper--5XsvZ";
export var hamburgerWrapper = "Navbar-module--hamburgerWrapper--3KpiW";
export var pchNavigation = "Navbar-module--pchNavigation--22Qmy";
export var phoneLink = "Navbar-module--phoneLink--RlHCC";
export var iconCorrection = "Navbar-module--iconCorrection--3fhen";
export var navList = "Navbar-module--navList--2kUpx";
export var listItem = "Navbar-module--listItem--2KRIF";
export var experienceButton = "Navbar-module--experienceButton--2IqZW";
export var pulseCorrection = "Navbar-module--pulseCorrection--2yzfR";