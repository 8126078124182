import React, { FunctionComponent, ReactElement } from 'react';
import * as styles from './Laboratory.module.scss';

export const Laboratory: FunctionComponent = (): ReactElement => {
    return (
        <>
            <section className={styles.laboratory}>
                <h2
                    data-aos="fade-up"
                    data-aos-duration="900"
                >{`Pre-operative History & Physical Examination`}</h2>
                <p data-aos="fade-up" data-aos-duration="900">
                    {`A `}
                    <b>{`pre-operative physical examination `}</b>
                    {`
                        is generally performed upon the request of a surgeon to ensure that a patient is healthy enough to safely undergo anesthesia and surgery. This evaluation usually includes a physical examination, cardiac evaluation, lung function assessment, and appropriate laboratory tests.
                    `}
                </p>
                <p data-aos="fade-up" data-aos-duration="900">
                    {`
                        The patient should ideally be evaluated several weeks before the operation. The 
                    `}
                    <b>{`history `}</b>
                    {`
                        should include information about the condition for which the surgery is planned, any past surgical procedures and the patient's experience with anesthesia.
                    `}
                </p>
            </section>
        </>
    );
}
