import React, {
    FunctionComponent,
    ReactElement,
} from 'react';
import {
    useStaticQuery,
    graphql,
} from 'gatsby';
import { ProviderSummary2Query } from './ProviderSummary2.interfaces';
import * as styles from './ProviderSummary2.module.scss';

export const ProviderSummary2: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<ProviderSummary2Query>(graphql`
        query {
            doctorProfile: file(name: {eq: "doctorProfile"}, extension: {eq: "png"}) {
                publicURL
            }
        }
    `);

    return (
        <section className={styles.providerSummary2}>
            <div
                className={styles.imageWrapper}
                data-aos="fade-right"
                data-aos-duration="900"
            >
                <img
                    alt="Doctor profile image"
                    src={queryResult?.doctorProfile.publicURL}
                    loading="lazy"
                />
            </div>
            <div
                className={styles.contentWrapper}
                data-aos="fade-left"
                data-aos-duration="900"
            >
                <h2>{`Faster Payment`}</h2>
                <p>
                    {`
                        Becoming a provider for PCH will give you a steady stream of
                        patient referrals without added marketing costs. We make the
                        referral process as streamlined and efficient as possible.
                        That means less paperwork and faster payment for you!
                    `}
                </p>
            </div>
        </section>
    );
}
