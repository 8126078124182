import React, {
    FunctionComponent,
    ReactElement,
    useEffect,
} from 'react';
import { motion } from 'framer-motion';
import { Props } from './GeneralJumbotron.interfaces';
import * as styles from './GeneralJumbotron.module.scss';

export const GeneralJumbotron: FunctionComponent<Props> = (props: Props): ReactElement<Props> => {
    useEffect(() => {
        const $section = document.querySelector(`.${styles.generalJumbotronSection}`);

        $section?.setAttribute('style', `
            background-image: linear-gradient(rgba(14, 116, 188, 0.8), rgba(14, 116, 188, 0.8)), url("${props.backgroundImageURL}");
            background-position: center 40%;
            background-size: cover;
            background-repeat: no-repeat;
        `);
    }, []);

    return (
        <section className={styles.generalJumbotronSection}>
            <div className={styles.contentWrapper}>
                <motion.h2
                    className={styles.title}
                    initial={{ opacity: 0, y: 150 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ ease: 'easeOut', duration: 0.5 }}
                >{props.title}</motion.h2>
            </div>
        </section>
    );
}
