import React, {
    FunctionComponent,
    ReactElement,
    useContext,
} from 'react';
import {
    useStaticQuery,
    graphql,
    Link,
} from 'gatsby';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FetchMobileNavImagesQuery }  from './MobileNav.interfaces';
import * as styles from './MobileNav.module.scss';
import {
    Interface,
    InterfaceType,
} from '../../contexts';

export const MobileNav: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<FetchMobileNavImagesQuery>(graphql`
        query {
            logo: file(name: {eq: "pci"}, extension: {eq: "png"}) {
                publicURL
            }
        }
    `);
    const { toggleNav, isNavOpen } = useContext<InterfaceType>(Interface);
    const variants = {
        open: { opacity: 1, x: 0 },
        closed: { opacity: 0, x: '100%' },
    };

    return (
        <motion.aside
            initial="closed"
            animate={isNavOpen ? 'open' : 'closed'}
            variants={variants}
            transition={{ ease: 'easeInOut', duration: 0.3 }}
            id={styles.pchMobileNavigation}
        >
            <div className={styles.mobileHeadingWrapper}>
                <Link to="/">
                    <img
                        className={styles.logo}
                        src={queryResult?.logo.publicURL}
                        alt="PCH logo"
                        loading="lazy"
                    />
                </Link>
                <button
                    className={styles.closeNav}
                    type="button"
                    onClick={() => toggleNav(false)}
                ><FontAwesomeIcon icon={faTimes}/></button>
            </div>
            <div className={styles.mobileLinksWrapper}>
                <ul className={styles.linksList}>
                    <li className={styles.linkItem}><Link className={styles.link} to="/attorney">{`Attorney`}</Link></li>
                    <li className={styles.linkItem}><Link className={styles.link} to="/provider">{`Provider`}</Link></li>
                    <li className={styles.linkItem}><Link className={styles.link} to="/telemedicine">{`Telemedicine`}</Link></li>
                    <li className={styles.linkItem}><Link className={styles.link} to="/about-us">{`About Us`}</Link></li>
                </ul>
            </div>
        </motion.aside>
    );
}
