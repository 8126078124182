import React, { FunctionComponent, ReactElement } from 'react';
import { Link } from 'gatsby';
import * as styles from './SponsorsButton.module.scss';

export const SponsorsButton: FunctionComponent = (): ReactElement => {
    return (
        <Link
            className={`${styles.sponsorsLink} animate__animated animate__pulse animate__infinite`}
            to="/sponsors"
        >{`Sponsors`}</Link>
    );
}
