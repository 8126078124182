import React, {
    FunctionComponent,
    ReactElement,
    useEffect,
} from 'react';
import {
    useStaticQuery,
    graphql,
} from 'gatsby';
import { PharmacyJumbotronQuery } from './PharmacyJumbotron.interfaces';
import * as styles from './PharmacyJumbotron.module.scss';

export const PharmacyJumbotron: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<PharmacyJumbotronQuery>(graphql`
        query {
            pharmacyBackground: file(name: {eq: "pharmacyBackground"}, extension: {eq: "png"}) {
                publicURL
            }
        }
    `);

    useEffect(() => {
        // Get section element
        const sectionEl: HTMLElement | null = document.querySelector(`.${styles.pharmacyJumbotron}`);

        // Set the background image
        sectionEl?.setAttribute('style', `
            background-image: linear-gradient(rgba(14, 116, 188, 0.8), rgba(14, 116, 188, 0.8)), url("${queryResult.pharmacyBackground.publicURL}");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        `);
    }, []);

    return (
        <section className={styles.pharmacyJumbotron}>
            <div className={styles.contentWrapper}>
                <h1
                    data-aos="fade-up"
                    data-aos-duration="900"
                >{`Pharmacy`}</h1>
            </div>
        </section>
    );
}
