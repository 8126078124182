import React, {
    FunctionComponent,
    ReactElement,
} from 'react';
import { Helmet } from 'react-helmet';
import {
    graphql,
    useStaticQuery,
} from 'gatsby';
import {
    Props,
    FetchSiteMetadataQuery,
} from './SEO.interfaces';

export const SEO: FunctionComponent<Props> = ({ description, lang = 'en', meta = [], keywords = [], title = '' }: Props): ReactElement<Props> =>
{
    const queryResult = useStaticQuery<FetchSiteMetadataQuery>(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
        }
    `);

    const metaDescription: string = description || queryResult.site.siteMetadata.description;
    const siteTitle: string = title || queryResult.site.siteMetadata.title;

    return (
        <Helmet
            htmlAttributes={{ lang, }}
            title={siteTitle}
            titleTemplate={`%s`}
            meta={[
                { name: 'description', content: metaDescription, },
                { property: 'og:title', content: siteTitle, },
                { property: 'og:description', content: metaDescription, },
                { property: 'og:type', content: 'website', },
                { name: 'twitter:card', content: 'summary', },
                { name: 'twitter:creator', content: queryResult.site.siteMetadata.author, },
                { name: 'twitter:title', content: siteTitle, },
                { name: 'twitter:description', content: metaDescription, },
            ].concat(
                keywords && (keywords.length > 0) ?
                { name: 'keywords', content: keywords.join(', '), } :
                []
            ).concat(((meta.length > 0) ? meta : []))}
        >
            <script>{`(function(){var s = document.createElement('script'),e = ! document.body ? document.querySelector('head') : document.body;s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';s.async = true;s.onload = function(){acsbJS.init({statementLink : '',footerHtml : '',hideMobile : false,hideTrigger : false,language : 'en',position : 'right',leadColor : '#146FF8',triggerColor : '#146FF8',triggerRadius : '50%',triggerPositionX : 'right',triggerPositionY : 'bottom',triggerIcon : 'people',triggerSize : 'medium',triggerOffsetX : 20,triggerOffsetY : 20,mobile : {triggerSize : 'small',triggerPositionX : 'right',triggerPositionY : 'center',triggerOffsetX : 0,triggerOffsetY : 0,triggerRadius : '50%'}});};e.appendChild(s);}());`}</script>
        </Helmet>
    );
};
